import { Link } from '@/components/Link'
import { CookieBanner } from '@/features/cookies/CookieBanner'
import { useCookiesConfig } from '@/features/cookies/use-cookies'
import { PAGES } from '@/routes'
import { PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const NotFoundPage = ({ pageContext }: PageProps<never, { language: string }>): JSX.Element => {
  const { t, i18n } = useTranslation('nsNotFound')
  const cookiesConfig = useCookiesConfig()

  if (i18n.language !== pageContext.language) {
    i18n.changeLanguage(pageContext.language)
  }

  return (
    <>
      <Helmet>
        <title>404</title>
        <meta name='robots' content='noindex, nofollow' />
        <meta name='facebook-domain-verification' content='gdt37htl4djqwmarnkdyct76lx1zos' />
        {/* Google Tag Manager */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && (
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T432GJH');
        `}</script>
        )}
        {/* End Google Tag Manager */}

        {/* Disallow cache */}
        <meta http-equiv='cache-control' content='max-age=0' />
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <meta http-equiv='expires' content='Tue, 01 Jan 1980 1:00:00 GMT' />
        <meta http-equiv='pragma' content='no-cache' />
      </Helmet>

      <div className='bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8'>
        <div className='max-w-max mx-auto'>
          <main className='sm:flex'>
            <p className='text-4xl font-extrabold text-blue-600 sm:text-5xl'>404</p>
            <div className='sm:ml-6'>
              <div className='sm:border-l sm:border-gray-200 sm:pl-6'>
                <h1 className='text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl'>
                  {t('title')}
                </h1>
                <p className='mt-1 text-base text-gray-500'>{t('description')}</p>
              </div>
              <div className='mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6'>
                <Link
                  to={PAGES.HOME}
                  className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                >
                  {t('backToHome')}
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>

      <CookieBanner />
    </>
  )
}

export default NotFoundPage
